import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import _ from "underscore";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import validator from "validator";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";
import AttributeComboBox from "./AttributeComboBox";
import Tags from "../tag/Tags";
import Chip from "@mui/material/Chip";
import DetailService from "./DetailService";
import CloseIcon from "@mui/icons-material/Close";
import GatewayStatusPagination from "./GatewayStatusPagination";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ListAltIcon from "@mui/icons-material/ListAlt";
import MapIcon from "@mui/icons-material/Map";
import Map from "./Map";
import { IoWaterOutline } from "react-icons/io5";
import MonthlyConsumption from "./MonthlyConsumption";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarRateIcon from "@mui/icons-material/StarRate";
import NetworkCheckIcon from "@mui/icons-material/NetworkCheck";
import Item from "../../../../components/ui/Item";
import { useTranslation } from "react-i18next";
import NetworkInfo from "./NetworkInfo";
import { frFR } from "@mui/x-data-grid/locales";
import BatteryIcon from "Components/ui/BatteryIcon";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import TabPlace from "./TabPlace";
import DownlinkMain from "./downlink/DownlinkMain";
import SendIcon from "@mui/icons-material/Send";
import SensorDetailColumn from "./SensorDetailColumn";
import { LicenseInfo } from "@mui/x-license-pro";
LicenseInfo.setLicenseKey("a1fd23001c4bdc757249008f1924cc20Tz04Mjg4MyxFPTE3Mzc3NDk0MDEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

const ATIM_THX_PRODUCT_ID = "e60ffd73-1aa7-4029-bc21-cbe678dd2f7d";

export default function SensorDetailDialog({ open, setOpen, user, selectedThingId }) {
    const { t, i18n } = useTranslation();
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: 10 });

    const [thingName, setThingName] = useState("");
    const [thingDetail, setThingDetail] = useState();
    const [thingTags, setThingTags] = useState([]);
    const [deveui, setDeveui] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [previousSortQuery, setPreviousSortQuery] = useState("");
    const [selectedAttributeId, setSelectedAttributeId] = useState("all");
    const [selectedCompany, setSelectedCompany] = useState();
    const [lastGatewayStatus, setLastGatewayStatus] = useState([]);
    const [isAtimTHX, setIsAtimTHX] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);

    const [position, setPosition] = useState([45.49573, -73.60635]);
    const [gps, setGps] = useState();
    const [monthlyConsumption, setMonthlyConsumption] = useState();

    const [star, setStar] = useState(false);
    const [allAttributes, setAllAttributes] = useState([]);
    const columns = SensorDetailColumn.getColumns(t);

    const handleTabs = (event, newValue) => {
        setTabIndex(newValue);
    };

    const handleClose = () => {
        setOpen(isSaved);
        setGps(undefined);
        setStar(false);
    };

    useEffect(() => {
        if (open) {
            DetailService.requestThing(selectedThingId, setThing).then((r) => {
                setIsAtimTHX(r.product.id === ATIM_THX_PRODUCT_ID);
            });
            DetailService.requestMeasurements(
                paginationModel.page,
                paginationModel.pageSize,
                setRows,
                selectedThingId,
                previousSortQuery,
                selectedAttributeId,
                setRowCount
            );

            DetailService.requestLastGatewayStatus(selectedThingId, setLastGatewayStatus);

            // for map
            DetailService.requestLastGps(selectedThingId, setPosition, setGps);

            DetailService.requestMonthlyConsumption(selectedThingId, setMonthlyConsumption, setTabIndex);

            if (allAttributes) {
                DetailService.requestFavoriteAttribute(user.cognitoUserName, selectedThingId, undefined, setStar).then((r) => {
                    const all = allAttributes.length - 1;
                    const registered = r.length;

                    if (all == registered) {
                        setStar(true);
                    }
                });
            }
        }
    }, [open, paginationModel.page, paginationModel.pageSize, selectedAttributeId, user, star, allAttributes]);

    const setThing = (thing) => {
        setThingDetail(thing);
        setThingName(thing.displayName);

        if (thing.customFields != undefined) {
            thing.customFields.forEach((cf) => {
                if (cf.name == "devEui") {
                    setDeveui(cf.value);
                }
            });
        }

        setThingTags(
            thing.tags.map((ele) => {
                return {
                    id: ele.id,
                    label: ele.tag,
                };
            })
        );

        setSelectedCompany(thing.company);
    };

    const handleChange = (event) => {
        setThingName(event.target.value);
        setIsSaved(true);
    };

    const handleUpdate = (event) => {
        if (validator.isEmpty(thingName)) {
            return;
        }
        setIsSaving(true);
        DetailService.updateName(selectedThingId, thingName, thingDetail, thingNameCallback);
    };

    const thingNameCallback = (name) => {
        setIsSaving(false);
        setThingName(name);
    };

    const handleSortModelChange = (param) => {
        let sortModel = param;

        let qParams = [];
        _.each(sortModel, function (sm) {
            let result = "";

            switch (sm.field) {
                case "timestamp":
                    result += "measure.timestamp";
                    break;
                case "measurement":
                    result += "attribute.name";
                    break;
                default:
                    break;
            }

            switch (sm.sort) {
                case "asc":
                    result += "&dir=ASC";
                    break;
                case "desc":
                    result += "&dir=DESC";
                    break;
                default:
                    break;
            }

            qParams.push(result);
        });

        let sortQuery = "";
        if (qParams.length > 0) {
            sortQuery = "&sort=" + qParams.join(",");
        }

        if (previousSortQuery != sortQuery) {
            setPreviousSortQuery(sortQuery);
            DetailService.requestMeasurements(
                paginationModel.page,
                paginationModel.pageSize,
                setRows,
                selectedThingId,
                sortQuery,
                selectedAttributeId,
                setRowCount
            );
        }
    };

    const handleSelectedAttributeId = (attribute_id) => {
        // console.log("handleSelectedAttributeId...///...")
        setSelectedAttributeId(attribute_id);

        // console.log(props.user.cognitoUserName)
        // console.log(selectedThingId)
        // console.log(attribute_id)

        DetailService.requestFavoriteAttribute(user.cognitoUserName, selectedThingId, attribute_id, setStar);
    };

    const handleFavorite = (e) => {
        // console.log(e)
        // console.log("selectedAttributeId");
        // console.log(props.user.cognitoUserName)
        // console.log(selectedAttributeId)
        DetailService.updateFavoriteAttribute(star, user.cognitoUserName, selectedAttributeId, allAttributes, selectedThingId).then(() => setStar(!star));
    };

    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogTitle id="form-dialog-title">
                    {t("de.sensor_detail")}
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{ position: "absolute", right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent className="d-flex flex-fill" style={{ overflow: "hidden" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={4} style={{ marginTop: "10px" }}>
                                <Stack spacing={1} alignItems="center" justifyContent="center">
                                    <Item>
                                        <TextField
                                            id="standard-basic"
                                            variant="standard"
                                            fullWidth
                                            editable={true}
                                            value={thingName}
                                            onChange={handleChange}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton edge="end" color="primary" onClick={handleUpdate} size="large">
                                                            {!isSaving && <SaveIcon />}
                                                            {isSaving && (
                                                                <Box sx={{ display: "flex" }}>
                                                                    <CircularProgress size={20} />
                                                                </Box>
                                                            )}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </Item>
                                    <Item style={{ width: "100%" }}>
                                        <Stack direction={"row"} spacing={2}>
                                            <Item>{getStatus(thingDetail)}</Item>
                                            <Item>
                                                <BatteryIcon thingDetail={thingDetail} />
                                            </Item>
                                        </Stack>
                                    </Item>
                                    <Item>
                                        {thingDetail != undefined && thingDetail.product && (
                                            <img
                                                src={`/static/catalog/products/${thingDetail.product.id}/image.png`}
                                                alt="Product"
                                                style={{ display: "block", margin: "auto", maxHeight: "150px", width: "98%", height: "auto" }}
                                            ></img>
                                        )}
                                    </Item>
                                </Stack>
                                <Stack>
                                    {/* {deveui != undefined && deveui != "" && (
                                        <Item style={{ width: "100%", display: "flex", alignContent: "flex-start" }}>DevEUI: {deveui}</Item>
                                    )} */}
                                    <Tags selectedThingId={selectedThingId} selectedCompany={selectedCompany} thingTags={thingTags}></Tags>
                                </Stack>
                            </Grid>
                            <Grid item xs={8} style={{ marginTop: "10px" }}>
                                <Stack spacing={0}>
                                    <Grid container spacing={0} sx={{ p: 0, m: 0 }}>
                                        <Grid item xs={12}>
                                            <Box sx={{ width: "100%" }}>
                                                <Tabs value={tabIndex} onChange={handleTabs} aria-label="list or map" sx={{ m: 0 }}>
                                                    <Tab
                                                        icon={<IoWaterOutline size={21} color={"#666666"} />}
                                                        sx={{ flexGrow: 1 }}
                                                        hidden={monthlyConsumption ? false : true}
                                                    />
                                                    <Tab icon={<ListAltIcon />} sx={{ flexGrow: 1 }} />
                                                    <Tab icon={<HomeWorkIcon />} sx={{ flexGrow: 1 }} />
                                                    <Tab icon={<MapIcon />} sx={{ flexGrow: 1 }} />
                                                    <Tab icon={<NetworkCheckIcon />} sx={{ flexGrow: 1 }} />
                                                    <Tab icon={<SendIcon />} sx={{ flexGrow: 1 }} hidden={isAtimTHX ? false : true} />
                                                </Tabs>
                                            </Box>
                                            {tabIndex === 0 && <Box sx={{ marginTop: "0px", width: "200px", height: "0px" }}>&nbsp;</Box>}
                                            {tabIndex === 1 && (
                                                <Box sx={{ marginTop: "20px", width: "250px", display: "flex" }}>
                                                    <AttributeComboBox
                                                        selectedThingId={selectedThingId}
                                                        open={open}
                                                        callbackSelectedAttributeId={handleSelectedAttributeId}
                                                        callbackAllAttributes={setAllAttributes}
                                                    ></AttributeComboBox>
                                                    <IconButton aria-label="favorite" onClick={() => handleFavorite()} size="large">
                                                        {star && <StarRateIcon />}
                                                        {!star && <StarOutlineIcon />}
                                                    </IconButton>
                                                </Box>
                                            )}
                                            {tabIndex === 3 && <Box sx={{ marginTop: "0px", width: "200px", height: "0px" }}>&nbsp;</Box>}
                                            {tabIndex === 4 && <Box sx={{ marginTop: "0px", width: "200px", height: "0px" }}>&nbsp;</Box>}
                                        </Grid>

                                        {tabIndex === 0 && monthlyConsumption && (
                                            <Grid item xs={12} style={{ padding: "0px", width: 400, height: 570 }}>
                                                <MonthlyConsumption monthlyConsumption={monthlyConsumption} />
                                            </Grid>
                                        )}
                                        {tabIndex === 1 && (
                                            <Grid
                                                item
                                                xs={12}
                                                style={{
                                                    padding: "0px",
                                                    width: 400,
                                                    maxHeight: 500,
                                                    height: 452,
                                                }}
                                            >
                                                <DataGridPro
                                                    rows={rows}
                                                    rowCount={rowCount}
                                                    columns={columns}
                                                    paginationModel={paginationModel}
                                                    pagination={true}
                                                    paginationMode="server"
                                                    sortingMode="server"
                                                    pageSizeOptions={[10, 50, 100]}
                                                    onPaginationModelChange={setPaginationModel}
                                                    checkboxSelection={false}
                                                    onSortModelChange={handleSortModelChange}
                                                    density="compact"
                                                    filterMode="server"
                                                    disableMultipleColumnsFiltering={true}
                                                    disableColumnReorder={true}
                                                    sx={{
                                                        "& .MuiTablePagination-selectLabel": {
                                                            marginTop: 2,
                                                        },
                                                        "& .MuiTablePagination-displayedRows": {
                                                            marginTop: 2,
                                                        },
                                                        // height: 400,
                                                        // marginBottom: 20,
                                                    }}
                                                    initialState={{
                                                        columns: {
                                                            columnVisibilityModel: {
                                                                id: false,
                                                            },
                                                        },
                                                    }}
                                                    localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                                                    autoHeight={false}
                                                />
                                            </Grid>
                                        )}

                                        {tabIndex === 2 && (
                                            <Grid item xs={12} style={{ marginTop: "20px", padding: "0px", width: 400, height: 556 }}>
                                                <TabPlace selectedThingId={selectedThingId} />
                                            </Grid>
                                        )}

                                        {tabIndex === 3 && (
                                            <Grid item xs={12} style={{ width: 400, height: 520, padding: "0px", paddingTop: "20px" }}>
                                                <Map name={thingDetail ? thingDetail.name : ""} position={position} gps={gps} />
                                            </Grid>
                                        )}
                                        {tabIndex === 4 && (
                                            <Grid item xs={12} style={{ width: 400, height: 520, padding: "0px" }}>
                                                <NetworkInfo selectedThingId={selectedThingId} />
                                                <GatewayStatusPagination lastGatewayStatus={lastGatewayStatus} selectedThingId={selectedThingId} />
                                            </Grid>
                                        )}
                                        {tabIndex === 5 && (
                                            <Grid item xs={12} style={{ width: 400, height: 520, padding: "0px" }}>
                                                <DownlinkMain />
                                            </Grid>
                                        )}
                                    </Grid>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}

function getStatus(thingDetail) {
    if (thingDetail != undefined) {
        // console.log(thingDetail.status);
        switch (thingDetail.status) {
            case "ACTIVE":
            case "CONNECTED":
                // return <span className={'badge ' + style.active} >{thingDetail.status}</span>
                return (
                    <Chip label={thingDetail.status} size={"small"} style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#9cc96b", color: "#fff" }} />
                );
            case "INACTIVE":
            case "NOT_CONNECTED":
            case "JOIN_RECEIVED":
                // return <span className={'badge ' + style.inactive} >{thingDetail.status}</span>
                return (
                    <Chip
                        label={thingDetail.status}
                        size={"small"}
                        style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#E7E955", color: "#404044" }}
                    />
                );
            case "ERROR":
                // return <span className={'badge ' + style.error} >{thingDetail.status}</span>
                return (
                    <Chip label={thingDetail.status} size={"small"} style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#e44959", color: "#fff" }} />
                );
            default:
                return (
                    <Chip
                        label={thingDetail.status}
                        size={"small"}
                        style={{ fontSize: "11px", fontWeight: 400, backgroundColor: "#E7E955", color: "#404044" }}
                    />
                );
        }
    }
}
