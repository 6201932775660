import AWS from "aws-sdk";

// Configure AWS SDK with credentials and region
AWS.config.update({
    region: process.env.REACT_APP_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

const s3 = new AWS.S3();

const AwsS3Service = {
    uploadDoc: async (key, body) => {
        try {
            const params = {
                Bucket: process.env.REACT_APP_DOC_BUCKET,
                Key: key,
                Body: body,
            };
            // console.log(params);

            const response = await s3.upload(params).promise();
            // console.log("Upload Success", response);
            return response;
        } catch (error) {
            console.error("Error uploading file", error);
            throw error;
        }
    },

    deleteDoc: async (key) => {
        try {
            const params = {
                Bucket: process.env.REACT_APP_DOC_BUCKET,
                Key: key,
            };
            const response = await s3.deleteObject(params).promise();
            // console.log("Delete Success", response);
            return response;
        } catch (error) {
            console.error("Error deleting file", error);
            throw error;
        }
    },
};

export default AwsS3Service;
