import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem, TextField, FormControl, Grid, Typography, InputAdornment, ListItemText } from "@mui/material";
import CustomStyledButton from "../../../../../components/ui/CustomStyledButton";
import { useTranslation } from "react-i18next";

const keepAliveOptions = [
    { key: "disable", value: "Disable" },
    { key: "hourly", value: "Once every hour" },
    { key: "2_hours", value: "Once every 2 hours" },
    { key: "4_hours", value: "Once every 4 hours" },
    { key: "8_hours", value: "Once every 8 hours" },
    { key: "1_day", value: "Once every day" },
    { key: "2_days", value: "Once every 2 days" },
    { key: "3_days", value: "Once every 3 days" },
    { key: "4_days", value: "Once every 4 days" },
    { key: "weekly", value: "Once every week" },
    { key: "monthly", value: "Once every month (30 days)" },
];

export default function DownlinkMain() {
    const { t, i18n } = useTranslation();

    const [keepAlive, setKeepAlive] = useState(keepAliveOptions[0].key);
    const [tempHigh, setTempHigh] = useState(20.0);
    const [tempLow, setTempLow] = useState(10.0);
    const [humidityHigh, setHumidityHigh] = useState(20);
    const [humidityLow, setHumidityLow] = useState(0.4);

    useEffect(() => {
        console.log("uef in DownlinkMain");
    }, []);

    return (
        <Box sx={{ mt: 2 }}>
            <Grid container sx={{ alignItems: "center" }}>
                <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t("de.downlink_keep_alive")}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth margin="normal">
                        <Select value={keepAlive} onChange={(e) => setKeepAlive(e.target.value)} sx={{ "& .MuiInputBase-input": { padding: "10px" } }}>
                            {keepAliveOptions.map((option) => (
                                <MenuItem key={option.key} value={option.key}>
                                    <ListItemText primary={option.value} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t("de.downlink_temp_threshold")}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: "flex", justifyContent: "row", gap: 2, flexDirection: "row", alignItems: "center" }}>
                        <Typography width={50}>{t("de.downlink_high")}</Typography>
                        <TextField
                            type="number"
                            inputProps={{ step: "0.1", style: { textAlign: "right" } }}
                            value={tempHigh}
                            onChange={(e) => setTempHigh(parseFloat(e.target.value))}
                            fullWidth
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "row", gap: 2, flexDirection: "row", alignItems: "center" }}>
                        <Typography width={50}>{t("de.downlink_low")}</Typography>
                        <TextField
                            type="number"
                            inputProps={{ step: "0.1", style: { textAlign: "right" } }}
                            value={tempLow}
                            onChange={(e) => setTempLow(parseFloat(e.target.value))}
                            fullWidth
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Typography variant="subtitle1" gutterBottom>
                        {t("de.downlink_humidite_threshold")}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Box sx={{ display: "flex", justifyContent: "row", gap: 2, flexDirection: "row", alignItems: "center" }}>
                        <Typography width={50}>{t("de.downlink_high")}</Typography>
                        <TextField
                            type="number"
                            inputProps={{ step: "0.1", style: { textAlign: "right" } }}
                            value={humidityHigh}
                            onChange={(e) => setHumidityHigh(parseFloat(e.target.value))}
                            fullWidth
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%RH</InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "row", gap: 2, flexDirection: "row", alignItems: "center" }}>
                        <Typography width={50}>{t("de.downlink_low")}</Typography>
                        <TextField
                            type="number"
                            inputProps={{ step: "0.1", style: { textAlign: "right" } }}
                            value={humidityLow}
                            onChange={(e) => setHumidityLow(parseFloat(e.target.value))}
                            fullWidth
                            margin="normal"
                            InputProps={{
                                endAdornment: <InputAdornment position="end">%RH</InputAdornment>,
                            }}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                    <CustomStyledButton name={t("de.downlink_send")} />
                </Grid>
            </Grid>
        </Box>
    );
}
