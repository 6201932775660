import React, { useEffect, useState, useRef } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { GridRowModes, DataGridPro, GridToolbarContainer, GridRowEditStopReasons, useGridApiContext } from "@mui/x-data-grid-pro";
import { randomCreatedDate, randomTraderName, randomId, randomArrayItem } from "@mui/x-data-grid-generator";
import MaintService from "./MaintService";
import UserService from "Services/userService";
import { getColumns } from "./getColumns";
import { useTranslation } from "react-i18next";
import { frFR } from "@mui/x-data-grid/locales";
import AwsS3Service from "./AwsS3Service";
import moment from "moment";
import ConfirmDialog from "./ConfirmDialog";

const roles = ["Market", "Finance", "Development"];
const randomRole = () => {
    return randomArrayItem(roles);
};

const initialRows = [
    {
        id: randomId(),
        name: randomTraderName(),
        age: 25,
        joinDate: randomCreatedDate(),
        role: randomRole(),
    },
];

function EditToolbar(props) {
    const { setRows, setRowModesModel, t } = props;

    const handleClick = () => {
        const id = randomId();
        setRows((oldRows) => [...oldRows, { id, name: "", age: "", isNew: true }]);
        setRowModesModel((oldModel) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
        }));
    };

    return (
        <GridToolbarContainer>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
                {t("label.add_record")}
            </Button>
        </GridToolbarContainer>
    );
}

export default function MaintMain({ thingId }) {
    const apiRef = useRef(null);
    const { t, i18n } = useTranslation();
    const [rows, setRows] = useState(initialRows);
    const [rowCount, setRowCount] = useState(0);
    const [rowModesModel, setRowModesModel] = useState({});
    const [pagination, setPagination] = useState({
        page: 0,
        pageSize: 20,
    });
    const [sort, setSort] = useState([{ field: "creationDate", sort: "desc" }]);
    const [reload, setReload] = useState();

    const fileInputRef = useRef(null); // Create a ref for the file input
    const [openConfirm, setOpenConfirm] = useState(false);
    const [selectedMaint, setSelectedMaint] = useState();
    const [user, setUser] = useState();
    const [selectedMaintId, setSelectedMaintId] = useState();

    useEffect(() => {
        UserService.getAuthenticatedUser().then((u) => {
            setUser(u);
            MaintService.requstMaint(thingId, u.companyId, pagination.page, pagination.pageSize, sort, setRows, setRowCount);
        });
    }, [reload]);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (!selectedFile) return;

        const docKey = moment().unix() + "_" + selectedFile.name;
        try {
            AwsS3Service.uploadDoc(docKey, selectedFile).then((r) => {
                const maintId = selectedMaintId;
                const fileName = selectedFile.name;
                const location = r.Location;
                const bucket = r.Bucket;

                MaintService.uploadDoc(maintId, fileName, location, bucket, docKey).then(() => setReload(moment()));
            });
        } catch (error) {
            console.error(error);
        }
    };

    const handleRowEditStop = (params, event) => {
        if (params.reason === GridRowEditStopReasons.rowFocusOut) {
            event.defaultMuiPrevented = true;
        }

        const { id, field } = params;
        const { value } = event.target;

        if (field === "creationDate") {
            MaintService.updateMaint(id, value, undefined, user.companyId, thingId);
        } else if (field === "description") {
            MaintService.updateMaint(id, undefined, value, user.companyId, thingId);
        }
    };

    const handleEditClick = (id) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (row) => () => {
        const { id } = row;
        if (!apiRef.current) return;

        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });

        const updatedRow = apiRef.current?.getRowWithUpdatedValues(id);
        if (!updatedRow) return;

        const description = updatedRow["description"];
        const creationDate = updatedRow["creationDate"];

        MaintService.updateMaint(id, creationDate, description, user.companyId, thingId).then(() => setReload(moment()));
    };

    const handleDeleteClick = (maint) => {
        // console.log("handleDeleteClick.........");
        // console.log(maint);

        const promises = []; // Create an array to hold promises
        maint.row?.maintDoc.forEach((m) => {
            promises.push(AwsS3Service.deleteDoc(m.docKey)); // Add each promise to the array
        });

        Promise.all(promises)
            .then(() => {
                // console.log("Done");
                // console.log(maint);

                MaintService.deleteMaint(maint.id).then(() => {
                    setRows(rows.filter((row) => row.id !== maint.id));
                });
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    const handleCancelClick = (id) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow?.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        return updatedRow;
    };

    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };

    const handleFileUpload = (id) => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the file input click
        }

        // Before saving a row, the user attempts to upload a file first. This code supports that.
        const updatedRow = apiRef.current?.getRowWithUpdatedValues(id);
        if (!updatedRow) return;
        const description = updatedRow["description"];
        const creationDate = updatedRow["creationDate"];
        MaintService.updateMaint(id, creationDate, description, user.companyId, thingId);

        setSelectedMaintId(id);
    };

    const columns = getColumns(
        t,
        rowModesModel,
        handleSaveClick,
        (props) => {
            setSelectedMaint(props);
            setOpenConfirm(true);
        },
        handleCancelClick,
        handleEditClick,
        setReload,
        handleFileUpload
    );

    return (
        <Box
            sx={{
                height: 500,
                width: "100%",
                "& .actions": {
                    color: "text.secondary",
                },
                "& .textPrimary": {
                    color: "text.primary",
                },
            }}
        >
            <DataGridPro
                apiRef={apiRef}
                rows={rows}
                rowCount={rowCount}
                columns={columns}
                editMode="row"
                rowModesModel={rowModesModel}
                onRowModesModelChange={handleRowModesModelChange}
                onRowEditStop={handleRowEditStop}
                processRowUpdate={processRowUpdate}
                slots={{
                    toolbar: EditToolbar,
                }}
                slotProps={{
                    toolbar: { setRows, setRowModesModel, t },
                }}
                pagination={true}
                paginationMode="server"
                paginationModel={pagination}
                onPaginationModelChange={setPagination}
                pageSizeOptions={[20]}
                sortingMode="server"
                sortModel={sort}
                onSortModelChange={setSort}
                localeText={i18n.language === "fr" ? frFR.components.MuiDataGrid.defaultProps.localeText : undefined}
                columnVisibilityModel={{
                    id: false,
                }}
            />
            <input type="file" ref={fileInputRef} onChange={handleFileChange} />
            <ConfirmDialog maint={selectedMaint} open={openConfirm} setOpen={setOpenConfirm} callback={handleDeleteClick} />
        </Box>
    );
}
