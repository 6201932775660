import React, { useState, useEffect, useRef } from "react";
import { Text, Image, Rect, Group } from "react-konva";

export default function KonvaImage({ data, image }) {
    const [state, setState] = useState({
        isDragging: false,
        x: 0,
        y: 0,
    });

    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipX, setTooltipX] = useState(0);
    const [tooltipY, setTooltipY] = useState(0);
    const [tooltipText, setTooltipText] = useState();
    const [tooltipHeight, setTooltipHeight] = useState(0);
    const tooltipRef = useRef(null);

    useEffect(() => {
        // console.log("useEffect in KonvasImage");
        console.log(data);

        if (data) {
            let position = data
                .filter((d) => d.position)
                .map((r) => r.position)
                .shift();

            if (position) {
                setState({
                    isDragging: false,
                    x: position.x,
                    y: position.y,
                });

                setTooltipX(position.x + 50);
                setTooltipY(position.y);
            }

            let arrText = [];

            let room_name = data
                .filter((d) => d.floor)
                .map((r) => r.floor + " " + r.name)
                .shift();
            arrText.push(room_name);

            if (data) {
                data.forEach((d) => {
                    if (d.co2) {
                        arrText.push("co2: " + d.co2.value + " " + d.co2.unit);
                        arrText.push("humidity: " + d.humidity.value + " " + d.humidity.unit);
                        arrText.push("temperature: " + d.temperature.value + " " + d.temperature.unit);
                    } else if (d.electricity) {
                        arrText.push("electricity: " + d.electricity.value + " " + d.electricity.unit);
                    }
                });
            }

            // console.log("length::" + arrText.length);

            setTooltipHeight(arrText.length * 16);

            setTooltipText(arrText.join("\n"));
        }
    }, []);

    return (
        <>
            <Image
                image={image}
                x={state.x}
                y={state.y}
                width={50}
                height={50}
                draggable
                onDragStart={() => {
                    setState({
                        isDragging: true,
                    });
                }}
                onDragEnd={(e) => {
                    setState({
                        isDragging: false,
                        x: e.target.x(),
                        y: e.target.y(),
                    });

                    setTooltipX(e.target.x() + 50);
                    setTooltipY(e.target.y());
                }}
                onMouseEnter={(e) => {
                    // console.log(e);
                    setTooltipVisible(true);
                }}
                onMouseOut={(e) => {
                    // console.log(e);
                    setTooltipVisible(false);
                }}
            />

            <Tooltip ref={tooltipRef} text={tooltipText} x={tooltipX} y={tooltipY} visible={tooltipVisible} tooltipHeight={tooltipHeight} />
        </>
    );
}

const Tooltip = ({ text, x, y, visible, tooltipHeight }) => {
    return (
        <Group visible={visible} x={x} y={y}>
            <Rect fill="black" opacity={0.75} cornerRadius={5} width={150} height={tooltipHeight} />
            <Text text={text} fill="white" padding={5} fontSize={14} />
        </Group>
    );
};
